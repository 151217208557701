import { fontStyle } from 'styled-system'

const theme = {
  config: {
    initialColorModeName: 'intial',
  },
  colors: {
    offWhite: 'rgb(237 236 239 / 90%)',
    text: 'hsl(10, 20%, 20%)',
    para: '#48434f',
    background: '#fff',
    primary: '#130b2e',
    dropdown: '#130b2e',
    accent: '#444382',
    darkPurple: '#241f38',
    dropdownText: 'darkPurple',
    dropdownHover: 'hsl(260, 49%, 49%, 1)',
    whiteToDarkPurple: '#fff',
    primaryDarker: '#2C5747',
    secondary: '#454382',
    secondaryhover: '#ffc107',
    header: '#494091',
    highlight: 'hsl(10, 40%, 90%)',
    purple: 'hsl(250, 60%, 30%)',
    mutedBlack: '#1b1b1b',
    darkBlack: '#161616',
    white: '#fdfdfe',
    mutedText: '#9babc5',
    muted: 'hsl(10, 20%, 94%)',
    linkItemHover: '#ececec',
    buttonActive: '#4218ec',
    borderButtons: '#463198',
    alwaysDarkSecondary: '#e4cd88',
    modes: {
      dark: {
        para: '#fff',
        whiteToDarkPurple: '#241f38',
        dropdownText: 'rgb(237 236 239 / 90%)',
        text: '#fff',
        secondary: '#ffc107',
        background: '#241f38',
        primary: '#e4cd88',
        header: '#e4cd88',
        accent: '#514eef',
        linkItemHover: '#1f1f1f',
        headerLinkHover: '#7a20f1',
        borderButtons: '#232222',
      },
    },
  },
  fonts: {
    body: '"Asap", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  fontWeights: {
    body: 400,
    heading: 700,
    display: 900,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  text: {
    heading: {
      fontFamily: 'heading',
      fontWeight: 'heading',
      lineHeight: 'heading',
      footer: {
        fontSizes: [2, 3],
      },
    },
    h1: {
      fontSize: ['2.5rem', '3.5rem'],
      marginBottom: ['0.5em', '0.5em'],
      fontFamily: 'Yeseva One',
      color: 'header',
      fontWeight: 'bold',
    },
    h1Centered: {
      marginBottom: ['0.5em', '0em'],
      color: 'header',
      fontSize: ['2.2em', '2.5em', '3em'],
      fontWeight: 'bold',
      margin: 0,
      textAlign: ['center', 'center', 'center', 'left'],
      fontFamily: 'Yeseva One',
    },
    upperHeading: {
      marginBottom: ['0.5em', '0.5em'],
      textTransform: 'uppercase',
      fontSize: '1em',
      textAlign: ['center', 'center', 'center', 'left'],
      color: '#c5a33f',
      fontWeight: 600,
      letterSpacing: '0.075em',
    },
    featureHeading: {
      fontSize: ['3rem', '4rem'],
      marginBottom: '0em',
      lineHeight: '1.3em',
      fontWeight: 700,
      fontFamily: 'Yeseva One',
      color: 'white',
      '@media only screen and (max-width: 450px)': {
        fontSize: '2em',
      },
    },
    featureSubheading: {
      fontFamily: 'Yeseva One',
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: ['14px', '1.2rem'],
      textTransform: 'uppercase',
      color: '#f3c382',
      '&.small': {
        fontSize: '1.4rem',
      },
    },
    featureParagraph: {
      fontSize: ['1.1rem', '1.1rem', '1.25rem'],
      lineHeight: ['1.8rem', '2rem', '2rem'],
      fontWeight: [600, 400],
      marginTop: '1em',
      '@media only screen and (max-width: 450px)': {
        fontSize: '0.9rem',
        lineHeight: '1.5rem',
        fontWeight: 500,
        marginTop: '0.5em',
      },
    },
    paragraph: {
      color: 'para',
      fontWeight: 500,
      fontSize: '1.125rem',
      lineHeight: 1.5,
      margin: [
        '1.3em auto 0em',
        '1.3em auto 0em',
        '1.3em auto 0em',
        '1.3em 0 0em',
      ],
      maxWidth: '40rem',
    },
    paragraphCenter: {
      variant: 'text.paragraph',
      textAlign: ['center', 'center', 'center', 'left'],
    },
    paragraphAlwaysWhite: {
      variant: 'text.paragraph',
      color: 'white',
      '@media only screen and (max-width: 450px)': { fontSize: '1em'}
    },
    quote: {
      variant: 'text.paragraphCenter',
      fontSize: '1rem',
      fontStyle: 'italic',
      margin: ['1.3em 1em', '1.3em auto'],
      maxWidth: '35rem',
    },
    xl: {
      fontSize: ['3.8rem', '4.5rem', '5.5rem'],
      marginBottom: '0.2em',
      fontFamily: 'Yeseva One',
      color: 'accent',
      '@media only screen and (max-width: 450px)': {
        fontSize: '3em',
      },
    },
    large: {
      fontSize: ['2rem', '2.5rem', '3rem'],
      marginBottom: '0em',
      fontFamily: 'Yeseva One',
      color: '#e4cd88',
      '@media only screen and (max-width: 450px)': { fontSize: '1.8em'}
    },
    medium: {
      fontSize: ['2rem', '2.5rem'],
      marginBottom: '0em',
      fontFamily: 'Yeseva One',
      color: 'primary',
      '@media only screen and (max-width: 450px)': { fontSize: '1.8em'}
    },
    mediumAlwaysDark: {
      fontSize: ['2rem', '2.5rem'],
      marginBottom: '0em',
      fontFamily: 'Yeseva One',
      color: '#e4cd88',
      '@media only screen and (max-width: 450px)': { fontSize: '1.8em'}
    },
    p: {
      fontSize: ['14px', '17.6px'],
      marginBottom: '1.45rem',
      color: 'text',
    },
    display: {
      variant: 'textStyles.heading',
      fontSize: [5, 6],
      fontWeight: 'display',
      letterSpacing: '-0.03em',
      mt: 3,
    },
    dropdown: {
      fontSizes: [2],
    },
    smallHeading: {
      fontSize: ['16px', '20px'],
    },
    sidebarHeading: {
      margin: '1em 1em',
      fontWeight: 600,
      textTransform: 'uppercase',
      fontSize: '10px',
    },
    emphasize: {
      fontWeight: 700,
    },
    italicize: {
      fontStyle: 'italic',
    },
    dropdownHeading: {
      color: ['offWhite', 'offWhite', 'offWhite', 'dropdownText'],
      '&:hover': {
        color: '#fff',
      },
    },
    dropdownParagraph: {
      color: 'dropdownText',
      '&:hover': {
        color: '#fff',
      },
    },
    caption: {
      textTransform: 'uppercase',
      alignSelf: 'end',
      color: '#c5a33f',
      fontSize: '0.8em',
      fontWeight: 700,
      marginBottom: '-0.5em',
    },
  },
  left: {
    marginRight: 'auto',
  },
  right: {
    justifySelf: 'end',
  },
  rounded: {
    borderRadius: '5px',
  },
  badge: {
    base: {
      borderRadius: '20px',
      width: '20px',
      height: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'primary',
      color: 'white',
      position: 'absolute',
      right: '20px',
      marginBottom: '0.2em',
      padding: '0.5em 1em',
      paddingTop: '0.8em',
      fontWeight: 'bold',
    },
    circle: {
      variant: 'badge.base',
      padding: '0',
      paddingTop: '0.3em',
    },
  },
  links: {
    base: {
      fontSize: 16,
      textDecoration: 'none',
      border: 'none',
      color: 'black',
      fontFamily: 'Arvo',
      '&.active': {
        color: 'primary',
      },
    },
    header: {
      variant: 'links.base',
      fontSize: [18, 19, 16],
      fontWeight: [500],
      textAlign: ['left', 'left', 'left', 'center'],
      padding: ['0.5em 0', '0.5em 0', '0.5em 0', '0'],
      lineHeight: ['2em'],
      transition: '0.2s all linear',
      textDecoration: 'none',
      marginBottom: '0',
      '&.active': {
        fontWeight: [700],
      },
      '&:hover': {
        transform: 'translateY(-2px)',
      },
    },
    footer: {
      variant: 'links.base',
      lineHeight: '1.7em',
      color: 'white',
      textDecoration: 'none',
    },
    sidebar: {
      variant: 'links.base',
      lineHeight: '3em',
      color: 'black',
      fontSize: '14px',
      fontWeight: 700,
      fontFamily: 'Khula',
      letterSpacing: '0.5px',
      '&.active': {
        bg: 'pinkToPurple',
      },
      '&:hover': {
        color: 'inherit',
      },
    },
  },
  label: {
    marginBottom: 1,
    fontWeight: 700,
    fontFamily: 'Arvo',
    color: '#e4cd88',
    fontSize: '16px',
    marginTop: '1em',
    '@media only screen and (max-width: 500px)': { fontSize: '14px', marginTop: '0.8em'}
  },
  select: {
    backgroundColor: 'hsl(0,0%,100%)',
    borderColor: 'hsl(0,0%,80%)',
  },
  textarea: {
    backgroundColor: 'hsl(0,0%,100%)',
    borderColor: 'hsl(0,0%,80%)',
  },
  input: {
    backgroundColor: 'hsl(0,0%,100%)',
    borderColor: 'hsl(0,0%,80%)',
    base: {
      fontSize: 14,
      borderRadius: '4px',
      backgroundColor: 'hsl(0,0%,100%)',
      borderColor: 'hsl(0deg 0% 80% / 47%)',
      border: '1px solid rgba(35,33,41,0.1)',
      caretColor: 'primary',
      color: 'hsl(10, 20%, 20%)',
      padding: '8px',
      width: '100%',
      transition: 'all 250ms cubic-bezier(0.4,0,0.2,1)',
      minHeight: '40px',
      '&:focus': {
        outline: 0,
        boxShadow: t => `inset 0 0 0 2px ${t.colors.primary}`,
      },
    },
  },
  grids: {
    formRow: {
      marginTop: '0.5em',
    },
    header: {
      margin: '0 auto',
      maxWidth: `1450px`,
      width: ['95%', '95%', '95%', '95%', '90%'],
      padding: `1.45rem 0rem`,
      alignItems: `center`,
      position: 'relative',
      bg: 'primary',
    },
    headerShort: {
      variant: 'grids.header',
      position: 'relative',
      maxHeight: '75px',
      zIndex: '9999999',
      width: ['100%', '100%', '100%', '100%'],
      margin: '0 auto',
      marginBottom: '0',
      padding: '0',
      bg: 'transparent',
      alignItems: 'center',
      justifyContent: 'center'
    },
    headerBio: {
      variant: 'grids.headerShort',
      backgroundColor: 'background',
    },
    nav: {
      width: ['100%', 'initial'],
      textAlign: ['left', 'center'],
      position: ['fixed', 'initial'],
      zIndex: [4, 0],
      flexDirection: ['column', 'row'],
      margin: ['0em', '0.5em 0em'],
      padding: ['1em 2em', '1em 2em', '1em 2em', '0'],
      fontWeight: [800, 800, 800, 600],
      alignItems: 'center',
    },
    dropdownGrid: {
      backgroundColor: [
        'transparent',
        'transparent',
        'transparent',
        'whiteToDarkPurple',
      ],
      borderRadius: '5px',
      boxShadow: ['none', 'none', 'none', '0px 4px 8px rgb(12 12 12 / 61%)'],
    },
    dropdownItem: {
      textDecoration: 'none',
      '&:hover': {
        backgroundColor: 'rgb(46 32 112 / 78%)',
      },
    },
  },
  layout: {
    padding: ['4.3em 2em 5em', '8em 0'],
    width: ['95%', '90%', '95%', '80%'],
    margin: ['0 auto'],
    maxWidth: 1300,
    '@media only screen and (max-width: 450px)': {
      padding: '4.3em 0em 5em',
    },
    base: {
      border: '0',
    },
    top: {
      marginTop: '4em 0',
      position: 'relative',
    },
    primary: {
      padding: ['4.3em 2em 5em', '8em 0'],
      width: ['95%', '90%', '95%', '80%'],
      margin: ['0 auto'],
      maxWidth: 1300,
    },
    form: {
      variant: 'layout.container',
      width: '100%',
      flexDirection: 'column',
      margin: '0 auto',
      '@media only screen and (max-width: 450px)': {
        padding: '0',
      },
    },
    fluid: {
      variant: 'layout.container',
      padding: ['4.3em 0'],
      marginBottom: '1em',
    },
    fill: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
  },
  buttons: {
    base: {
      bg: 'alwaysDarkSecondary',
      fontSize: '14px',
      color: 'black',
      fontWeight: 700,
      fontFamily: 'Arvo',
      outline: 0,
      padding: '0.5em 1em',
      margin: '1em 0',
      width: 'fit-content',
      '&:hover': {
        bg: '#d4a006',
        transition: '0.1s ease',
      },
    },
    dropdown: {
      variant: 'links.header',
      backgroundColor: 'transparent',
      textDecoration: 'none',
      height: '100%',
      padding: ['0.5em 0', '0.5em 0', '0.5em 0', '0.4em 0.8em'],
      transition: '0.2s all linear',
      '&.active': {
        fontWeight: [500],
      },
      '&:hover': {
        color: 'inherit',
        transform: 'translateY(-2px)',
      },
      '&.dark': {
        color: 'white',
      },
      '&:focus': {
        outline: '0',
      },
    },
    slider: {
      borderWidth: '2px',
      borderRadius: '9999px',
      outline: 0,
      borderColor: 'purple',
      backgroundColor: '#2d3748',
      width: '44px',
      height: '22px',
      padding: 0,
      order: [2, 2, 2, 4],
      justifySelf: 'end',
      marginRight: ['0', '0', '0', '1.5em'],
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    pre: {
      variant: 'prism',
      fontFamily: 'monospace',
      fontSize: 1,
      p: 3,
      color: 'text',
      bg: 'muted',
      overflow: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      color: 'secondary',
      fontSize: 1,
    },
    inlineCode: {
      fontFamily: 'monospace',
      color: 'secondary',
      bg: 'muted',
    },
    table: {
      width: '100%',
      my: 4,
      borderCollapse: 'separate',
      borderSpacing: 0,
      'th,td': {
        textAlign: 'left',
        py: '4px',
        pr: '4px',
        pl: 0,
        borderColor: 'muted',
        borderBottomStyle: 'solid',
      },
    },
    th: {
      verticalAlign: 'bottom',
      borderBottomWidth: '2px',
    },
    td: {
      verticalAlign: 'top',
      borderBottomWidth: '1px',
    },
    hr: {
      backgroundColor: '##2c5747',
      margin: ['0.5 0', '1em 0'],
      borderWidth: '0',
      width: ['100%', '440px'],
      height: '3px',
    },
    img: {
      maxWidth: '100%',
    },
    p: {
      fontFamily: 'monospace',
    },
  },
}

export default theme
